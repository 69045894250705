import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { CropFree, Print } from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { firebase } from "../services/config";
import logo from '../assets/bin-gerne-hier_Logo_2020.png'; 

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const PrintPage = (props) => {
  const classes = useStyles();

  const [entry, setEntry] = useState(null);
  const [myCodeData, setMyCodeData] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const url = "https://app.bin-gerne-hier.de/here/";

  useEffect(() => {
    const userId = props.user?.uid;
    console.log("userId", userId);
    if (userId) {
      setLoading(true);
      firebase
        .firestore()
        .collection("UserData")
        .where("authorUid", "==", userId)
        // .orderBy("created", "desc")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            const entity = doc.data();
            entity.id = doc.id;
            setEntry(entity);
            setLoading(false);
            console.log(entity);
          });
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
      // .onSnapshot(
      //   (querySnapshot) => {
      //     const newEntities = [];
      //     querySnapshot.forEach((doc) => {
      //       const entity = doc.data();
      //       entity.id = doc.id;
      //       newEntities.push(entity);
      //     });
      //     newEntities.sort((a, b) => b.created - a.created);
      //     setEntries(newEntities);
      //     setLoading(false);
      //   },
      //   (error) => {
      //     console.log(error);
      //     setLoading(false);
      //   }
      // );
    } else {
      console.log("userId", userId);
    }
  }, [props.user]);

  return (
    <>
      <HeaderBar {...props} title="QR-Code ausdrucken" user={props.user} />
      <br />
      <Container>
        <Grid container>
          <Grid item>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.pos}>
                  Persönlichen QR-Code ausdrucken.
                </Typography>
                <Typography variant="body2" component="p">
                  Hier können Sie Ihren persönlichen QR-Code, den Sie ausdrucken
                  und gut sichtbar bei Ihnen im Lokal, Restaurant oder
                  Unternehmen anbringen, ausdrucken.
                  <br />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.pos}>Vorschau</Typography>
                <Typography component="p">
                  Scannen Sie bitte den folgenden QR-Code mit der Smartphone-Kamera.<br />
                  Gästeregistrierung für die Corona-Gästeliste.<br />
                  Speise- und Getränkekarte.
                  <br />
                </Typography>
                <br />
                <br />
                {!loading ? (
                  entry && entry.id ? (
                    <>
                    <div className={classes.qrCodeDiv}>
                      <QRCode value={url + entry.id} size={256} renderAs="svg" />
                    </div>
                    <br />
                    <img src={logo} alt="Logo" />
                    </>
                  ) : showError ? (
                    <Typography>
                      Ihr QR-Code kann nicht generiert werden. Bitte Kontaktiere
                      Sie uns unter{" "}
                      <a href="mailto:support@bin-gerne-hier.de">
                        support@bin-gerne-hier.de
                      </a>
                    </Typography>
                  ) : (
                    ""
                  )
                ) : (
                  <CircularProgress />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <img
          width="1px"
          height="1px"
          className="noBorderImg"
          src="https://statistik.mowetec.com/matomo.php?idsite=22&amp;rec=1"
          alt=""
        />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default PrintPage;
