import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
  CardActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Person, Close, Check, AccountCircle } from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { uuidv4 } from "../services/uuid";
import { getMyDataGuest, getMyLocalId } from "../services/storageService";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const MyDataPage = (props) => {
  const classes = useStyles();
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [streetAndNumber, setStreetAndNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const firstNameMinLenght = 1;
  const firstNameMaxLenght = 50;
  const surnameMinLenght = 1;
  const surnameMaxLenght = 50;
  const streetAndNumberMinLenght = 1;
  const streetAndNumberMaxLenght = 50;
  const postalCodeMinLenght = 1;
  const postalCodeMaxLenght = 50;
  const cityMinLenght = 1;
  const cityMaxLenght = 50;
  const phoneMinLenght = 1;
  const phoneMaxLenght = 50;

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  useEffect(() => {
    if (localStorage.getItem("com.mowetec.coronaguestlist.myData")) {
      // Retrieve the object from storage
      var retrievedMyDataString = localStorage.getItem(
        "com.mowetec.coronaguestlist.myData"
      );

      var retrievedMyDataObject = JSON.parse(retrievedMyDataString);

      setId(retrievedMyDataObject.id);
      setFirstName(retrievedMyDataObject.firstName);
      setSurname(retrievedMyDataObject.surname);
      setStreetAndNumber(retrievedMyDataObject.streetAndNumber);
      setPostalCode(retrievedMyDataObject.postalCode);
      setCity(retrievedMyDataObject.city);
      setPhone(retrievedMyDataObject.phone);
      setEmail(retrievedMyDataObject.email);
    }
  }, []);

  const onSavePress = () => {
    if (
      firstName === "" ||
      surname === "" ||
      streetAndNumber.length < 4 ||
      postalCode.length != 5 ||
      city.length < 2 ||
      phone.length < 7
    ) {
      setOpenError(true);
    } else {
      var newId = id ? id : uuidv4();

      setId(newId);
      localStorage.setItem("com.mowetec.coronaguestlist.myLocalId", newId);

      var myData = {
        id: newId,
        firstName: firstName,
        surname: surname,
        streetAndNumber: streetAndNumber,
        postalCode: postalCode,
        city: city,
        phone: phone,
        email: email,
      };

      // Put the object into storage
      localStorage.setItem(
        "com.mowetec.coronaguestlist.myData",
        JSON.stringify(myData)
      );
      setOpenSuccess(true);
    }
  };

  return (
    <>
      <HeaderBar {...props} title="Meine Profildaten" user={props.user} />
      <br />
      <Container>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            {props.user ? (
              <>
                <Grid container>
                  <Grid item xs={2}>
                    <Check />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="p">
                      Sie sind angemeldet als
                      <br />
                      <b>{props.user.email}</b>.
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Check />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="p">
                      Ihre Rolle ist:
                      <br />
                      <b>Gastgeber / Gastwirt</b>.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={2}>
                    <Check />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography component="p">
                      Ihre Rolle ist:
                      <br />
                      <b>Gast / Besucher</b>.
                    </Typography>
                  </Grid>
                  {getMyLocalId() && getMyDataGuest() ? (
                    <>
                      <Grid item xs={2}>
                        <Check />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component="p">
                          Ihre Kontaktdaten sind bereits hinterlegt.
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={2}>
                        <Close />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component="p">
                          Ihre Kontaktdaten sind noch nicht hinterlegt.
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </CardContent>
          {!props.user ? (
            <CardActions>
              <Button size="small" component={Link} to="/login">
                <AccountCircle />
                &nbsp; Als Gastwirt / Gastgeber anmelden
              </Button>
            </CardActions>
          ) : (
            ""
          )}
        </Card>
        <br />
        <Grid>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={2}>
                  <Person />
                </Grid>
                <Grid item xs={10}>
                  <Typography component="p">Meine Profildaten</Typography>
                </Grid>
              </Grid>
              {/* <Typography className={classes.title} gutterBottom>
                <Person /> Meine Profildaten
              </Typography> */}
              <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  {props.user ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="streetAndNumber"
                          label="Name des Betriebs*"
                          fullWidth
                          value={streetAndNumber}
                          onChange={(event) =>
                            setStreetAndNumber(event.target.value)
                          }
                          error={streetAndNumber.length < 3}
                          helperText={
                            streetAndNumber.length < 3
                              ? "Bitte ausfüllen!"
                              : " "
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item xs={6}>
                    <TextField
                      id="firstName"
                      label="Vorname*"
                      fullWidth
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      error={firstName === ""}
                      helperText={firstName === "" ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="surname"
                      label="Name*"
                      fullWidth
                      value={surname}
                      onChange={(event) => setSurname(event.target.value)}
                      error={surname === ""}
                      helperText={surname === "" ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="streetAndNumber"
                      label="Straße und Hausnummer*"
                      fullWidth
                      value={streetAndNumber}
                      onChange={(event) =>
                        setStreetAndNumber(event.target.value)
                      }
                      error={streetAndNumber.length < 3}
                      helperText={
                        streetAndNumber.length < 3 ? "Bitte ausfüllen!" : " "
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="postalCode"
                      label="Postleitzahl*"
                      fullWidth
                      value={postalCode}
                      onChange={(event) => setPostalCode(event.target.value)}
                      error={postalCode.length != 5}
                      helperText={
                        (postalCode.length != 5) === ""
                          ? "Bitte eien gültige Postleitzahl eintragen!"
                          : " "
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="city"
                      label="Ort*"
                      fullWidth
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                      error={city.length < 2}
                      helperText={city.length < 2 ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="phone"
                      label="Telefon*"
                      fullWidth
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      error={phone.length < 7}
                      helperText={phone.length < 7 ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      type="email"
                      label="E-Mail"
                      fullWidth
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Grid>
                  {props.user ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="webAddress"
                          type="text"
                          label="Webseite"
                          fullWidth
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="facebookAddress"
                          type="text"
                          label="Facebook"
                          fullWidth
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="intagramAddress"
                          type="text"
                          label="Instagram"
                          fullWidth
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => onSavePress()}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSuccess}
          autoHideDuration={2000}
          onClose={handleCloseSuccess}
        >
          <Alert onClose={handleCloseSuccess} severity="success">
            Meine Daten wurden erfolgreich gespeichert.
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openError}
          autoHideDuration={4000}
          onClose={handleCloseError}
        >
          <Alert onClose={handleCloseError} severity="error">
            Bitte alle erforderlichen Felder, die mit * markiert sind, richtig
            ausfüllen.
          </Alert>
        </Snackbar>
        <br />
        <img
          width="1px"
          height="1px"
          className="noBorderImg"
          src="https://statistik.mowetec.com/matomo.php?idsite=22&amp;rec=1"
          alt=""
        />
      </Container>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default MyDataPage;
