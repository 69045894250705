import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import HeaderBar from "../components/HeaderBar";

function DatenschutzPage(props) {

  return (
    <>
      <HeaderBar title="Impressum" />
      <br />
      <Container>
        <Grid container>
          <Grid item>
        <div style={{ backgroundColor: "#FFFFFF" }}>
          <div className="container-fluid lg-p-top">
            <br />
            <div className="row">
              <div className="col">
                <h4>Datenschutz</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
              <br />
                <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von
                    personenbezogenen Daten (nachfolgend kurz „Daten“) im Rahmen der Erbringung unserer Leistungen sowie innerhalb
                    unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen
                    Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als
                    „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder
                    „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO). <br />
                    <br />
                    </p>
                    <h3 id="dsg-general-controller">Verantwortlicher</h3>
                    <p><span className="tsmcontroller">RICH-Informatik Ridvan Chasan<br />
                        Allensteiner Str. 37<br />
                        89584 Ehingen<br />
                        <br />
                        E-Mailadresse: support@bin-gerne-hier.de<br />
                        Geschäftsführer/ Inhaber: Ridvan Chasan<br />
                        Link zum Impressum: https://bin-gerne-hier.de/impressum<br /><br />

                        Kontakt Datenschutzbeauftragter: datenschutz@rich-informatik.de</span></p>
                    <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten</h3>
                    <p>- Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).<br />
                    - Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />
                    - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br />
                    - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br />
                    - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</p>
                    <h3 id="dsg-general-datasubjects">Kategorien betroffener Personen</h3>
                    <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend
                    auch als „Nutzer“).<br />
                    </p>
                    <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
                    <p>- Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.<br />
                    - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br />
                    - Sicherheitsmaßnahmen.<br />
                    - Reichweitenmessung/Marketing<br />
                    <span className="tsmcom"></span></p>
                    <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten </h3>
                    <p>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
                    natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person
                    angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
                    Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
                    Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
                    wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.<br />
                    <br />
                    „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
                    Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden
                    Umgang mit Daten.<br />
                    <br />
                    „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten
                    ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet
                    werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
                    organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer
                    identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.<br />
                    <br />
                    „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
                    personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
                    Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage,
                    Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel
                    dieser natürlichen Person zu analysieren oder vorherzusagen.<br />
                    <br />
                    Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
                    die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                    entscheidet, bezeichnet.<br />
                    <br />
                    „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
                    personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.<br />
                    </p>
                    <h3 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h3>
                    <p>Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Für
                    Nutzer aus dem Geltungsbereich der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern
                    die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, Folgendes: <br />
                    Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;<br />
                    Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher
                    Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;<br />
                    Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1
                    lit. c DSGVO;<br />
                    Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine
                    Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
                    Rechtsgrundlage.<br />
                    Die Rechtsgrundlage für die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im öffentlichen
                    Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde ist
                    Art. 6 Abs. 1 lit. e DSGVO. <br />
                    Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f
                    DSGVO. <br />
                    Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie erhoben wurden, bestimmt sich nach den
                    Vorgaben des Art 6 Abs. 4 DSGVO. <br />
                    Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den
                    Vorgaben des Art. 9 Abs. 2 DSGVO. <br />
                    </p>
                    <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
                    <p>Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter Berücksichtigung des Stands der Technik, der
                    Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
                    unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten
                    natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                    Schutzniveau zu gewährleisten.<br />
                    <br />
                    Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
                    Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der
                    Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
                    eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
                    Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
                    Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes
                    durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.<br />
                    </p>
                    <h3 id="dsg-general-coprocessing">Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und
                    Dritten</h3>
                    <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen
                    (Auftragsverarbeitern, gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln oder
                    ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B.
                    wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung erforderlich
                    ist), Nutzer eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer
                    berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.). <br />
                    <br />
                    Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, übermitteln oder ihnen sonst den
                    Zugriff gewähren, erfolgt dies insbesondere zu administrativen Zwecken als berechtigtes Interesse und
                    darüberhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden Grundlage. <br />
                    </p>
                    <h3 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h3>
                    <p>Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU), des Europäischen
                    Wirtschaftsraums (EWR) oder der Schweizer Eidgenossenschaft) verarbeiten oder dies im Rahmen der
                    Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an andere Personen oder
                    Unternehmen geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf
                    Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten
                    Interessen geschieht. Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich erforderlicher Übermittlung,
                    verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, zu denen
                    die unter dem "Privacy-Shield" zertifizierten US-Verarbeiter gehören oder auf Grundlage besonderer Garantien,
                    wie z.B. vertraglicher Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, dem Vorliegen
                    von Zertifizierungen oder verbindlichen internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, <a
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de"
                        target="blank">Informationsseite der EU-Kommission</a>).</p>
                    <h3 id="dsg-general-rightssubject">Rechte der betroffenen Personen</h3>
                    <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
                    Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen
                    Vorgaben.<br />
                    <br />
                    Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten
                    oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.<br />
                    <br />
                    Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverzüglich
                    gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
                    der Daten zu verlangen.<br />
                    <br />
                    Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
                    Maßgabe der gesetzlichen Vorgaben zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
                    <br />
                    <br />
                    Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen
                    Aufsichtsbehörde einzureichen.<br />
                    </p>
                    <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
                    <p>Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft zu widerrufen.</p>
                    <h3 id="dsg-general-object">Widerspruchsrecht</h3>
                    <p><strong>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe der gesetzlichen
                        Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
                        Direktwerbung erfolgen.</strong></p>
                    <h3 id="dsg-general-cookies">Cookies und Widerspruchsrecht bei Direktwerbung</h3>
                    <p>Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der
                    Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem
                    Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb
                    eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“,
                    werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen
                    Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein
                    Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach
                    dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die
                    Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer
                    gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“
                    werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt,
                    angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).<br />
                    <br />
                    Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer
                    Datenschutzerklärung auf.<br />
                    <br />
                    Sofern wir die Nutzer um eine Einwilligung in den Einsatz von Cookies bitten (z.B. im Rahmen einer
                    Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6 Abs. 1 lit. a. DSGVO. Ansonsten
                    werden die personenbezogenen Cookies der Nutzer entsprechend den nachfolgenden Erläuterungen im Rahmen dieser
                    Datenschutzerklärung auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
                    und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) oder sofern der
                    Einsatz von Cookies zur Erbringung unserer vertragsbezogenen Leistungen erforderlich ist, gem. Art. 6 Abs. 1
                    lit. b. DSGVO, bzw. sofern der Einsatz von Cookies für die Wahrnehmung einer Aufgabe, die im öffentlichen
                    Interesse liegt erforderlich ist oder in Ausübung öffentlicher Gewalt erfolgt, gem. Art. 6 Abs. 1 lit. e.
                    DSGVO, verarbeitet.<br />
                    <br />
                    Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die
                    entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in
                    den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
                    Funktionseinschränkungen dieses Onlineangebotes führen.<br />
                    <br />
                    Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei
                    einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite <a
                        href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die EU-Seite <a
                        href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren
                    kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden.
                    Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden
                    können.</p>
                    <h3 id="dsg-general-erasure">Löschung von Daten</h3>
                    <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht oder in ihrer
                    Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden
                    die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und
                    der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. <br />
                    <br />
                    Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind,
                    wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet.
                    Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.</p>
                    <h3 id="dsg-general-changes">Änderungen und Aktualisierungen der Datenschutzerklärung</h3>
                    <p>Wir bitten Sie sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
                    Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies
                    erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits
                    (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
                    <p></p>
                    <h3 id="dsg-google-cloud">Google&nbsp;Cloud-Dienste</h3>
                    <p></p>
                    <p><span className="ts-muster-content">Wir nutzen die von Google angebotene Cloud und die Cloud-Softwaredienste
                        (sog. Software as a Service, z.B. Google Suite) für die folgenden Zwecke: Dokumentenspeicherung und
                        Verwaltung, Kalenderverwaltung, E-Mail-Versand, Tabellenkalkulationen und Präsentationen, Austausch von
                        Dokumenten, Inhalten und Informationen mit bestimmten Empfängern oder Veröffentlichung von Webseiten,
                        Formularen oder sonstigen Inhalten und Informationen sowie Chats und Teilnahme an Audio- und
                        Videokonferenzen.<br />
                        <br />
                        Hierbei werden die personenbezogenen Daten der Nutzer verarbeitet, soweit diese Bestandteil der innerhalb
                        der&nbsp; beschriebenen Dienste verarbeiteten Dokumente und Inhalte werden oder Teil von
                        Kommunikationsvorgängen sind. Hierzu können z.B. Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen,
                        Verträgen, sonstigen Prozessen und deren Inhalte gehören. Google verarbeitet ferner Nutzungsdaten und
                        Metadaten, die von Google zu Sicherheitszwecken und Serviceoptimierung verwendet werden.<br />
                        <br />
                        Im Rahmen der Nutzung öffentlich zugängliche&nbsp; Dokumente, Webseiten oder sonstige&nbsp; Inhalte, kann
                        Google Cookies auf den Rechner der Nutzer für Zwecke der Webanalyse oder um sich Einstellungen der Nutzer zu
                        merken, speichern.<br />
                        <br />
                        Wir nutzen die Google Cloud-Dienste auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f
                        DSGVO an effizienten und sicheren Verwaltungs- und Zusammenarbeitsprozessen. Ferner erfolgt die Verarbeitung
                        auf Grundlage eines Auftragsverarbeitungsvertrages mit Google (<a target="_blank"
                        href="https://cloud.google.com/terms/data-processing-terms">https://cloud.google.com/terms/data-processing-terms</a>).<br />
                        <br />
                        Weitere Hinweise erhalten Sie in der Datenschutzerklärung von Google (<a target="_blank"
                        href="https://www.google.com/policies/privacy">https://www.google.com/policies/privacy</a>) und den
                        Sicherheitshinweisen zu Google Cloud-Diensten (<a target="_blank"
                        href="https://cloud.google.com/security/privacy/">https://cloud.google.com/security/privacy/</a>). Sie
                        können der Verarbeitung Ihrer Daten in der Google Cloud uns gegenüber entsprechend den gesetzlichen Vorgaben
                        widersprechen. Im Übrigen bestimmt sich die Löschung der Daten innerhalb von Googles Cloud-Diensten nach den
                        übrigen Verarbeitungsprozessen in deren Rahmen die Daten verarbeitet werden (z.B., Löschung für
                        Vertragszwecke nicht mehr erforderlicher oder Speicherung für Zwecke der Besteuerung erforderlicher
                        Daten).<br />
                        <br />
                        Die Google Cloud-Dienste werden von Google Irland Limited angeboten. Soweit eine Übermittlung in die USA
                        stattfindet, verweisen wir auf die Zertifizierung von Google USA unter dem Privacy Shield(<a target="_blank"
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&amp;status=Aktive">https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&amp;status=Aktive</a>)
                        und Standardschutzklauseln (<a target="_blank"
                        href="https://cloud.google.com/terms/data-processing-terms">https://cloud.google.com/terms/data-processing-terms</a>).</span>
                    </p>
                    <p></p>
                    <h3 id="dsg-registration">Registrierfunktion</h3>
                    <p></p>
                    <p><span className="ts-muster-content">Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden die
                        erforderlichen Pflichtangaben den Nutzern mitgeteilt und auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO zu
                        Zwecken der Bereitstellung des Nutzerkontos verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die
                        Login-Informationen (Name, Passwort sowie eine E-Mailadresse). Die im Rahmen der Registrierung eingegebenen
                        Daten werden für die Zwecke der Nutzung des Nutzerkontos und dessen Zwecks verwendet. <br />
                        <br />
                        Die Nutzer können über Informationen, die für deren Nutzerkonto relevant sind, wie z.B. technische
                        Änderungen, per E-Mail informiert werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im
                        Hinblick auf das Nutzerkonto, vorbehaltlich einer gesetzlichen Aufbewahrungspflicht, gelöscht. Es obliegt
                        den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt,
                        sämtliche während der Vertragsdauer gespeicherten Daten des Nutzers unwiederbringlich zu löschen.<br />
                        <br />
                        Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des
                        Nutzerkontos, speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung
                        erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und
                        sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie
                        ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu besteht eine gesetzliche
                        Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO. Die IP-Adressen werden spätestens nach 7 Tagen anonymisiert
                        oder gelöscht.<br />
                    </span></p>
                    <p></p>
                    <h3 id="dsg-hostingprovider">Hosting und E-Mail-Versand</h3>
                    <p></p>
                    <p><span class="ts-muster-content">Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
                        Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen,
                        Rechenkapazität, Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische
                        Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen. <br />
                        <br />
                        Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
                        Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
                        Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
                        Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
                        Auftragsverarbeitungsvertrag).</span></p>
                    <p></p>
                    <h3 id="dsg-logfiles">Erhebung von Zugriffsdaten und Logfiles</h3>
                    <p></p>
                    <p><span className="ts-muster-content">Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten
                        Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich
                        dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
                        Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf,
                        Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
                        IP-Adresse und der anfragende Provider.<br />
                        <br />
                        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
                        Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere
                        Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von
                        der Löschung ausgenommen.</span></p>
                    <p></p>
                    <h3 id="dsg-google-firebase">Google-Firebase</h3>
                    <p></p>
                    <p><span className="ts-muster-content">Wir nutzen die Entwicklerplattform „Google Firebase“ und die mit ihr
                        verbundenen Funktionen und Dienste, angeboten von Google Ireland Limited, Gordon House, Barrow Street,
                        Dublin 4, Irland.<br />
                        <br />
                        Bei Google Firebase handelt es sich um eine Plattform für Entwickler von Applikationen (kurz „Apps“) für
                        mobile Geräte und Webseiten. Google Firebase bietet eine Vielzahl von Funktionen, die auf der folgenden
                        Übersichtsseite dargestellt werden: <a target="_blank"
                        href="https://firebase.google.com/products/">https://firebase.google.com/products/</a>.<br />
                        <br />
                        Die Funktionen umfassen unter anderem die Speicherung von Apps inklusive personenbezogener Daten der
                        Applikationsnutzer, wie z.B. von ihnen erstellter Inhalte oder Informationen betreffend ihre Interaktion mit
                        den Apps (sog. „Cloud Computing“). Google Firebase bietet daneben Schnittstellen, die eine Interaktion
                        zwischen den Nutzern der App und anderen Diensten erlauben, z.B. die Authentifizierung mittels Diensten wie
                        Facebook, Twitter oder mittels einer E-Mail-Passwort-Kombination.<br />
                        <br />
                        Die Auswertung der Interaktionen der Nutzer kann mithilfe des Analyse-Dienstes „Firebase Analytics“
                        erfolgen. Firebase Analytics ist darauf gerichtet zu erfassen, wie Nutzer mit einer App interagieren. Dabei
                        werden Ereignisse (sog „Events“) erfasst, wie z.B. das erstmalige Öffnen der App, Deinstallation, Update,
                        Absturz oder Häufigkeit der Nutzung der App. Mit den Events können auch weitere Nutzerinteressen, z.B. für
                        bestimmte Funktionen der Applikationen oder bestimmte Themengebiete erfasst werden. Hierdurch können auch
                        Nutzerprofile erstellt werden, die z.B. als Grundlage für die Darstellung von auf Nutzer zugeschnittenen
                        Werbehinweisen, verwendet werden können.<br />
                        <br />
                        Google Firebase und die mittels von Google Firebase verarbeiteten personenbezogenen Daten der Nutzer können
                        ferner zusammen mit weiteren Diensten von Google, wie z.B. Google Analytics und den
                        Google-Marketing-Diensten und Google Analytics verwendet werden (in diesem Fall werden auch gerätebezogene
                        Informationen, wie „Android Advertising ID“ and „Advertising Identifier for iOS“ verarbeitet, um mobile
                        Geräte der Nutzer zu identifizieren).<br />
                        <br />
                        Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die
                        Rechtsgrundlage dieser Verarbeitung Art. 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen
                        Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
                        und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
                        verarbeitet.<br />
                        <br />
                        Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin, dass Google unter dem
                        Privacy-Shield-Abkommen zertifiziert ist und hierdurch zusichert, das europäische Datenschutzrecht
                        einzuhalten (<a target="_blank"
                        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>).<br />
                        <br />
                        Die Datenschutzerklärung von Google ist unter <a target="_blank"
                        href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> abrufbar. Weitere
                        Informationen zur Datennutzung zu Marketingzwecken durch Google, erfahren Nutzer auf der Übersichtsseite: <a
                        target="_blank"
                        href="https://policies.google.com/technologies/ads?hl=de">https://policies.google.com/technologies/ads?hl=de</a>,<br />
                        <br />
                        Wenn Nutzer der interessensbezogenen Werbung durch Google-Marketing-Dienste widersprechen möchten, können
                        Nutzer die von Google gestellten Einstellungs- und Opt-Out-Möglichkeiten nutzen: <a target="_blank"
                        href="https://adssettings.google.com/">https://adssettings.google.com/</a></span></p>
                    <p></p>
                    <h3 id="dsg-matomo">Reichweitenmessung mit Matomo</h3>
                    <p></p>
                    <p><span className="ts-muster-content">Im Rahmen der Reichweitenanalyse von Matomo werden die folgenden Daten
                        verarbeitet: der von Ihnen verwendete Browsertyp und die Browserversion, das von Ihnen verwendete
                        Betriebssystem, Ihr Herkunftsland, Datum und Uhrzeit der Serveranfrage, die Anzahl der Besuche, Ihre
                        Verweildauer auf der Website sowie die von Ihnen betätigten externen Links. Die IP-Adresse der Nutzer wird
                        anonymisiert, bevor sie gespeichert wird. <br />
                        <br />
                        Matomo verwendet Cookies, die auf dem Computer der Nutzer gespeichert werden und die eine Analyse der
                        Benutzung unseres Onlineangebotes durch die Nutzer ermöglichen. Dabei können aus den verarbeiteten Daten
                        pseudonyme Nutzungsprofile der Nutzer erstellt werden. Die Cookies haben eine Speicherdauer von einer Woche.
                        Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden nur auf unserem
                        Server gespeichert und nicht an Dritte weitergegeben.<br />
                        <br />
                        Nutzer können der anonymisierten Datenerhebung durch das Programm Matomo jederzeit mit Wirkung für die
                        Zukunft widersprechen, indem sie auf den untenstehenden Link klicken. In diesem Fall wird in ihrem Browser
                        ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten mehr erhebt. Wenn
                        Nutzer ihre Cookies löschen, so hat dies jedoch zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und
                        daher von den Nutzern erneut aktiviert werden muss.<br />
                        <br />
                        Die Logs mit den Daten der Nutzer werden nach spätestens 6 Monaten gelöscht.<br />
                        <br />
                        Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die
                        Rechtsgrundlage dieser Verarbeitung Art. 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen
                        Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
                        und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
                        verarbeitet.<br />
                        <br />
                        <iframe style={{ border: 0, height: 200, width: 600, }}
                    src="https://statistik.mowetec.com/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="></iframe>
                        </span></p>
                    <p></p>
                    <h3 id="dsg-socialmedia">Onlinepräsenzen in sozialen Medien</h3>
                    <p></p>
                    <p><span className="ts-muster-content">Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen,
                        um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen
                        informieren zu können.<br />
                        <br />
                        Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet
                        werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der
                        Rechte der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter die unter dem Privacy-Shield
                        zertifiziert sind, weisen wir darauf hin, dass sie sich damit verpflichten, die Datenschutzstandards der EU
                        einzuhalten.<br />
                        <br />
                        Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
                        z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt
                        werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb
                        der Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden
                        im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
                        Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der
                        von den Nutzern verwendeten Geräte gespeichert werden (insbesondere wenn die Nutzer Mitglieder der
                        jeweiligen Plattformen sind und bei diesen eingeloggt sind).<br />
                        <br />
                        Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten
                        Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1
                        lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der Plattformen um eine Einwilligung in die
                        vorbeschriebene Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1
                        lit. a., Art. 7 DSGVO.<br />
                        <br />
                        Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out),
                        verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.<br />
                        <br />
                        Auch im Fall von Auskunftsanfragen und der Geltendmachung von Nutzerrechten, weisen wir darauf hin, dass
                        diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
                        Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben.
                        Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.<br />
                        <br />
                        - Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
                        Irland) auf Grundlage einer <a target="_blank"
                        href="https://www.facebook.com/legal/terms/page_controller_addendum">Vereinbarung über gemeinsame
                        Verarbeitung personenbezogener Daten</a> - Datenschutzerklärung: <a target="_blank"
                        href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>, speziell für
                        Seiten: <a target="_blank"
                        href="https://www.facebook.com/legal/terms/information_about_page_insights_data">https://www.facebook.com/legal/terms/information_about_page_insights_data</a>
                        , Opt-Out: <a target="_blank"
                        href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a> und <a
                        target="_blank" href="http://www.youronlinechoices.com">http://www.youronlinechoices.com</a>, Privacy
                        Shield: <a target="_blank"
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>.<br />
                        <br />
                        - Google/ YouTube (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland) –
                        Datenschutzerklärung: &nbsp;<a target="_blank"
                        href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>, Opt-Out: <a
                        target="_blank"
                        href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>,
                        Privacy Shield: <a target="_blank"
                        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>.<br />
                        <br />
                        - Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) – Datenschutzerklärung/ Opt-Out:
                        <a target="_blank"
                        href="http://instagram.com/about/legal/privacy/">http://instagram.com/about/legal/privacy/</a>.<br />
                        <br />
                        - Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) -
                        Datenschutzerklärung: <a target="_blank"
                        href="https://twitter.com/de/privacy">https://twitter.com/de/privacy</a>, Opt-Out: <a target="_blank"
                        href="https://twitter.com/personalization">https://twitter.com/personalization</a>, Privacy Shield: <a
                        target="_blank"
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a>.<br />
                        <br />
                        - Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA) – Datenschutzerklärung/ Opt-Out: <a
                        target="_blank"
                        href="https://about.pinterest.com/de/privacy-policy">https://about.pinterest.com/de/privacy-policy</a>.<br />
                        <br />
                        - LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland) - Datenschutzerklärung <a
                        target="_blank"
                        href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a> ,
                        Opt-Out: <a target="_blank"
                        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>,
                        Privacy Shield:&nbsp;<a target="_blank"
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active</a>.<br />
                        <br />
                        - Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland) - Datenschutzerklärung/ Opt-Out: <a
                        target="_blank"
                        href="https://privacy.xing.com/de/datenschutzerklaerung">https://privacy.xing.com/de/datenschutzerklaerung</a>.<br />
                        <br />
                        - Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3 4PR, United Kingdom) - Datenschutzerklärung/
                        Opt-Out: <a target="_blank"
                        href="https://wakelet.com/privacy.html">https://wakelet.com/privacy.html</a>.<br />
                        <br />
                        - Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin, Deutschland) -
                        Datenschutzerklärung/ Opt-Out: <a target="_blank"
                        href="https://soundcloud.com/pages/privacy">https://soundcloud.com/pages/privacy</a>.</span></p><a
                    href="https://datenschutz-generator.de" className="dsg1-6" rel="nofollow" target="_blank">Erstellt mit
                    Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a>
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        </Grid>
        </Grid>
      </Container>
      <br />
      <br />
    </>
  );
}

export default DatenschutzPage;
