import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
  CardActionArea,
} from "@material-ui/core";
import {
  AccountCircle,
  Check,
  Close,
  CropFree,
  Edit,
  NavigateNext,
  Print,
  MenuBook
} from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { getMyLocalId, getMyDataGuest } from "../services/storageService";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardAction: {
    display: "block",
    textAlign: "initial",
  },
});

const StartPage = (props) => {
  const classes = useStyles();

  return (
    <>
      <HeaderBar {...props} title="Start" user={props.user} />
      <br />
      <Container>
        <Grid>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Grid container spacing={3}>
                {/* <Grid item xs={1}>
                  <Edit fontSize="large" />
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.pos}>
                    Herzlich Willkommen auf der Corona-Gästeliste von bin-gerne-hier.
                  </Typography>
                  {props.user ? (
                    <Grid container>
                      <Grid item xs={2}>
                        <Check />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component="p">
                          Sie sind angemeldet als
                          <br />
                          <b>{props.user.email}</b>.
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Check />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component="p">
                          Ihre Rolle ist:
                          <br />
                          <b>Gastgeber / Gastwirt</b>.
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={2}>
                        <Check />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component="p">
                          Ihre Rolle ist:
                          <br />
                          <b>Gast / Besucher</b>.
                        </Typography>
                      </Grid>
                      {getMyLocalId() && getMyDataGuest() ? (
                        <>
                          <Grid item xs={2}>
                            <Check />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography component="p">
                              Ihre Kontaktdaten sind bereits hinterlegt.
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={2}>
                            <Close />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography component="p">
                              Ihre Kontaktdaten sind noch nicht hinterlegt.
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                  {/* Bevor Sie sich in eine Gästeliste eintragen können, müssen
                    Sie Ihre Kontaktdaten hinterlegen. */}
                </Grid>
                {/* <Grid item xs={1}>
                  <NavigateNext fontSize="large" color="disabled" />
                </Grid> */}
              </Grid>
            </CardContent>
            {!props.user ? (
              <CardActions>
                <Button size="small" component={Link} to="/login">
                  <AccountCircle />
                  &nbsp; Als Gastwirt / Gastgeber anmelden
                </Button>
              </CardActions>
            ) : (
              ""
            )}
          </Card>
        </Grid>
        {!(getMyLocalId() && getMyDataGuest()) ? (
          <>
            <br />
            <Grid>
              <CardActionArea component={RouterLink} to="/my-data">
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <Edit fontSize="large" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h5" className={classes.pos}>
                          Kontaktdaten hinterlegen
                        </Typography>
                        <Typography variant="body2" component="p">
                          Bevor Sie sich in eine Gästeliste eintragen können,
                          müssen Sie Ihre Kontaktdaten hinterlegen.
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <NavigateNext fontSize="large" color="disabled" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </>
        ) : (
          ""
        )}
        {!props.user ? (
          <>
            <br />
            <Grid>
              <CardActionArea component={RouterLink} to="/my-code">
                <Card className={classes.root} variant="outlined">
                  {/* <ButtonBase className={classes.cardAction}> */}
                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faQrcode} size="3x" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h5" className={classes.pos}>
                          Meinen Code anzeigen
                        </Typography>
                        <Typography variant="body2" component="p">
                          Kontaktdaten bereits hinterlegt. Ein Gastwirt kann Ihren
                          Code einscannen und Sie automatisch in die Gästeliste
                          hinzufügen.
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <NavigateNext fontSize="large" color="disabled" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </>
        ) : (
          ""
        )}
        {props.user ? (
          <>
            <br />
            <Grid>
              <CardActionArea component={RouterLink} to="/scan">
                <Card className={classes.root} variant="outlined">
                  {/* <ButtonBase className={classes.cardAction}> */}
                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <CropFree fontSize="large" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h5" className={classes.pos}>
                          QR-Code einscannen
                        </Typography>
                        <Typography variant="body2" component="p">
                          Gäste über den QR-Code erfassen und schnell registrieren.
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <NavigateNext fontSize="large" color="disabled" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <br />
            <Grid>
              <CardActionArea component={RouterLink} to="/print">
                <Card className={classes.root} variant="outlined">
                  {/* <ButtonBase className={classes.cardAction}> */}
                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <Print fontSize="large" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h5" className={classes.pos}>
                          QR-Code ausdrucken
                        </Typography>
                        <Typography variant="body2" component="p">
                          QR-Code für den Betrieb generieren und ausdrucken.
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <NavigateNext fontSize="large" color="disabled" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <br />
            <Grid>
              <CardActionArea component={RouterLink} to="/editmenu">
                <Card className={classes.root} variant="outlined">
                  {/* <ButtonBase className={classes.cardAction}> */}
                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <MenuBook fontSize="large" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h5" className={classes.pos}>
                          Speise-/Gätrenkekarte hinterlegen
                        </Typography>
                        <Typography variant="body2" component="p">
                          Lassen Sie Ihre Gäste auf Ihre Speise-/Getränkekarte über den QR-Code zugreifen.
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <NavigateNext fontSize="large" color="disabled" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </>
        ) : (
          ""
        )}
        <br />
        <img width="1px" height="1px" className="noBorderImg" src="https://statistik.mowetec.com/matomo.php?idsite=22&amp;rec=1" alt="" />
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};

export default StartPage;
