import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from "@material-ui/core";
import { ControlPoint, ListAlt, Person, HelpRounded } from "@material-ui/icons";

const useStyles = makeStyles({
  stickToBottom: {
    width: "100%",
    // backgroundColor: "red",
    // paddingBottom: 30,
    // height: '110%',
    marginBottom: 10,
    position: "fixed",
    bottom: 0,
    borderTop: "solid",
    borderTopWidth: 1,
    // borderTopsStyle: "solid"
  },
  lineStickedToBottom: {
    width: "100%",
    height: 10,
    backgroundColor: "white",
    // paddingBottom: 30,
    // height: '110%',
    position: "fixed",
    bottom: 0,
  },
});

const TabNavbar = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState({});

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        //   className={classes.root}
        className={classes.stickToBottom}
      >
        <BottomNavigationAction
          label="Start"
          icon={<ControlPoint />}
          component={Link}
          to="/"
        />
        {!props.user ? (
          ""
        ) : (
          <BottomNavigationAction
            label="Einträge"
            icon={<ListAlt />}
            component={Link}
            to={!props.user ? "/entries" : "entries"}
          />
        )}
        <BottomNavigationAction
          label="Profil"
          icon={<Person />}
          component={Link}
          // to="/my-data"
          to={!props.user ? "/my-data" : "my-data"}
        />
        <BottomNavigationAction
          label="Hilfe"
          icon={<HelpRounded />}
          component={Link}
          to="/help"
        />
      </BottomNavigation>
      <div className={classes.lineStickedToBottom}></div>
    </>
  );
};

export default TabNavbar;
