import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { CropFree, Print } from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { firebase } from "../services/config";
import logo from "../assets/bin-gerne-hier_Logo_2020.png";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const MenuCardEditPage = (props) => {
  const classes = useStyles();

  const [entry, setEntry] = useState(null);
  const [myCodeData, setMyCodeData] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const url = "https://app.bin-gerne-hier.de/here/";

  useEffect(() => {
    const userId = props.user?.uid;
    console.log("userId", userId);
    if (userId) {
      setLoading(true);
      firebase
        .firestore()
        .collection("UserData")
        .where("authorUid", "==", userId)
        // .orderBy("created", "desc")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            const entity = doc.data();
            entity.id = doc.id;
            setEntry(entity);
            setLoading(false);
            console.log(entity);
          });
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
      // .onSnapshot(
      //   (querySnapshot) => {
      //     const newEntities = [];
      //     querySnapshot.forEach((doc) => {
      //       const entity = doc.data();
      //       entity.id = doc.id;
      //       newEntities.push(entity);
      //     });
      //     newEntities.sort((a, b) => b.created - a.created);
      //     setEntries(newEntities);
      //     setLoading(false);
      //   },
      //   (error) => {
      //     console.log(error);
      //     setLoading(false);
      //   }
      // );
    } else {
      console.log("userId", userId);
    }
  }, [props.user]);

  return (
    <>
      <HeaderBar {...props} title="Speisekarte bearbeiten" user={props.user} />
      <br />
      <Container>
        <Grid container>
          <Grid item>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.pos}>
                  Speisekarte hinterlegen
                </Typography>
                <Typography component="p">
                  Digitale Speise-/Getränkekarten über QR-Codes
                  <br />
                </Typography>
                <Typography component="p">
                  Erstellen Sie kostenlos ein digitales Menü für Ihr Restaurant
                  oder Ihre Bar.<br />
                  Lassen Sie Ihre Besucher über einen QR-Code Ihre
                  Speisekarte mit ihrem Telefon betrachten. Beschleunigen Sie
                  Ihre Bestellungen. <br />
                  <br />Ab 01.11.2020 kostenlos erhältlich.
                  <br />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <img
          width="1px"
          height="1px"
          className="noBorderImg"
          src="https://statistik.mowetec.com/matomo.php?idsite=22&amp;rec=1"
          alt=""
        />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default MenuCardEditPage;
