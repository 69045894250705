import { firebase } from "./config";

const getServerTimestamp = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};

export const createUserData = (uid, localId, role, businessName, postalCode, city) => {
  if (uid && localId && role) {
    const createdServer = getServerTimestamp();
    const userData = {
      localId: localId,
      businessName: businessName,
      postalCode: postalCode,
      city: city,
      role: role,
      created: new Date(),
      createdServer: createdServer,
      edited: new Date(),
      editedServer: createdServer,
      authorUid: uid,
    };
    firebase
      .firestore()
      .collection("UserData")
      .add(userData)
      .then((doc) => {
        return {
          message: "Success",
          data: doc,
        };
      })
      .catch((error) => {
        alert(error);
        return {
          message: error,
          data: null,
        };
      });
  } else {
    return {
      message:
        "Error during saving UserData. " +
        "Current user id (uid) is empty. User is unknown or not signed up.",
      data: null,
    };
  }
};

export const createEntry = (uid, localId, userData) => {
  if (uid && localId && userData) {
    const createdServer = getServerTimestamp();

    // TODO
    userData.created = new Date();
    userData.createdServer = createdServer;
    userData.edited = new Date();
    userData.editedServer = createdServer;
    userData.authorUid = uid;
    //   createdServer: createdServer,
    // const userData = {
    //   localId: localId,
    //   role: 'role',
    //   created: new Date(),
    //   createdServer: createdServer,
    //   edited: new Date(),
    //   editedServer: createdServer,
    //   authorUid: uid,
    // };

    firebase
      .firestore()
      .collection("Entry")
      .add(userData)
      .then((doc) => {
        return {
          message: "Success",
          data: doc,
        };
      })
      .catch((error) => {
        alert(error);
        return {
          message: error,
          data: null,
        };
      });
  } else {
    return {
      message:
        "Error during saving Entry. " +
        "Current user id (uid) is empty. User is unknown or not signed up.",
      data: null,
    };
  }
};

export const getEntries = () => {
  return firebase
    .firestore()
    .collection("Entry")
    .orderBy("created", "desc")
    .onSnapshot(
      (querySnapshot) => {
        const newEntities = [];
        querySnapshot.forEach((doc) => {
          const entity = doc.data();
          entity.id = doc.id;
          newEntities.push(entity);
        });
        //   setEntities(newEntities);
        return newEntities;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const getChecklists = () => {
  return firebase
    .firestore()
    .collection("Checklist")
    .orderBy("title", "asc")
    .onSnapshot(
      (querySnapshot) => {
        const newEntities = [];
        querySnapshot.forEach((doc) => {
          const entity = doc.data();
          entity.id = doc.id;
          newEntities.push(entity);
        });
        //   setEntities(newEntities);
        return newEntities;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const getChecklistsForCategory = (categoryId) => {
  return firebase
    .firestore()
    .collection("Checklist")
    .where("categoryId", "==", categoryId)
    .orderBy("title", "asc")
    .onSnapshot(
      (querySnapshot) => {
        const newEntities = [];
        querySnapshot.forEach((doc) => {
          const entity = doc.data();
          entity.id = doc.id;
          newEntities.push(entity);
        });
        //   setEntities(newEntities);
        return newEntities;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const getChecklistItems = (checklistId) => {
  return firebase
    .firestore()
    .collection("Checklist")
    .doc(checklistId)
    .collection("ChecklistItem")
    .orderBy("no", "asc")
    .onSnapshot(
      (querySnapshot) => {
        const newEntities = [];
        querySnapshot.forEach((doc) => {
          const entity = doc.data();
          entity.id = doc.id;
          newEntities.push(entity);
        });
        //   setEntities(newEntities);
        return newEntities;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const getMyChecklistsForCategory = (userId, categoryId) => {
  return firebase
    .firestore()
    .collection("MyChecklist")
    .where("authorUid", "==", userId)
    .where("categoryId", "==", categoryId)
    .orderBy("title", "asc")
    .onSnapshot(
      (querySnapshot) => {
        const newEntities = [];
        querySnapshot.forEach((doc) => {
          const entity = doc.data();
          entity.id = doc.id;
          newEntities.push(entity);
        });
        //   setEntities(newEntities);
        return newEntities;
      },
      (error) => {
        console.log(error);
        return null;
      }
    );
};

export const updateMyChecklist = (
  myChecklistOldId,
  title,
  description,
  category,
  userId
) => {
  const editedServer = getServerTimestamp();
  const myChecklist = {
    title: title,
    language: "de",
    description: description,
    category: category.split(";")[1],
    categoryId: category.split(";")[0],
    edited: new Date(),
    editedServer: editedServer,
    authorUid: userId,
    private: false,
  };

  firebase
    .firestore()
    .collection("MyChecklist")
    .doc(myChecklistOldId)
    .set(myChecklist, { merge: true })
    .then((doc) => {
      return {
        message: "Success",
        data: doc,
      };
      //   setTitle('');
      //   setDescription('');
      //   Keyboard.dismiss();
    })
    .catch((error) => {
      alert(error);
      return {
        message: error,
        data: null,
      };
    });
};

export const deleteMyChecklist = (myChecklistId) => {
  firebase
    .firestore()
    .collection("MyChecklist")
    .doc(myChecklistId)
    .delete()
    .then(() => {
      return {
        message: "Success",
        data: null,
      };
      //   setTitle('');
      //   setDescription('');
      //   Keyboard.dismiss();
    })
    .catch((error) => {
      alert(error);
      return {
        message: error,
        data: null,
      };
    });
};

export const deleteMyChecklistItem = (
  myChecklistId,
  myChecklistItemId,
  userId
) => {
  firebase
    .firestore()
    .collection("MyChecklist")
    .doc(myChecklistId)
    .collection("MyChecklistItem")
    .doc(myChecklistItemId)
    .delete()
    .then(() => {
      return {
        message: "Success",
        data: null,
      };
      //   setTitle('');
      //   setDescription('');
      //   Keyboard.dismiss();
    })
    .catch((error) => {
      alert(error);
      return {
        message: error,
        data: null,
      };
    });
};
