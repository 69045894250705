import React from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import HeaderBar from "../components/HeaderBar";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const HelpPage = () => {
  const classes = useStyles();

  return (
    <>
      <HeaderBar title="Hilfe" />
      <br />
      <Container>
        <Grid container spacing={2}>
          <Grid item>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.pos}>
                  Ablauf für die Gastwirte/Gastgeber:
                </Typography>
                <Typography variant="body2" component="p">
                  1. Betrieb/Gaststätte auf bin-gerne-hier registrieren.
                  <br />
                  2. Profildaten hinterlegen und einen QR-Code für Gäste
                  automatisch erhalten.
                  <br />
                  3. QR-Code ausdrucken und auf jedem Tisch im Betrieb
                  platzieren.
                  <br />
                  4. Nun können Gäste den QR-Code einscannen und die Gästeliste
                  ausfüllen und die Getränke-/Speisekarte anschauen.
                  <br />
                </Typography>
                <br />
                <br />
                <Typography className={classes.pos}>
                  Ablauf für die Gäste:
                </Typography>
                <Typography variant="body2" component="p">
                  1. QR-Code im Betrieb über die Kamera-App einscannen.
                  <br />
                  2. Profildaten hinterlegen und die Eintragung in die
                  Gästeliste durchführen.
                  <br />
                  2. Alternativ können Sie Ihren QR-Code auch durch den Gastwirt
                  einscannen lassen.
                  <br />
                  3. Getränke-/Speisekarte öffnen, Bestellung tätigen und die
                  Köstlichkeiten genießen.
                  <br />
                </Typography>
                <br />
                <br />
                <Typography className={classes.pos}>
                  Häufig gestellte Fragen
                </Typography>
                <Typography variant="body2" component="p">
                  <b>Kommen Kosten durch die Anwendung auf mich zu?</b>.<br />
                  Nein. Die Anwendung ist für Gäste und Gastwirte kostenlos.
                  <br />
                  <br />
                  <b>Wie lange müssen die Daten gespeichert werden?</b>.<br />
                  Zur Verfolgung und Unterbrechung von Infektionsketten werden
                  die Daten 14 Tage gespeichert und anschließend automatisch
                  gelöscht.
                  <br />
                  <br />
                  <b>Kann ich als Gastwirt die Daten selber löschen?</b>.<br />
                  Die Daten werden automatisch nach 14 Tagen gelöscht.
                  <br />
                  <br />
                  <b>Sind die Daten sicher?</b>.<br />
                  Die Daten sind in Ihrem geschützten Bereich abgelegt und nur
                  über ein Passwort erreichbar.
                  <br />
                  <br />
                  <b>
                    Wie kann ich bestehende Speisekarten für Kunden zur
                    Verfügung stellen?
                  </b>
                  .<br />
                  Diese Funktion wird ab dem 01.11.2020 in Ihrem geschützten
                  Bereich zur Verfügug stehen.
                  <br />
                  <br />
                  <br />
                  Weitere Fragen und Vorschläge können per WhatsApp an{" "}
                  <a
                    href="https://wa.me/+491759774531"
                    title="Per WhatsApp kontaktieren"
                  >
                    +49 175 97 74 531
                  </a>{" "}
                  oder per E-Mail an{" "}
                  <a
                    href="mailto:support@bin-gerne-hier.de"
                    title="E-Mail schreiben"
                  >
                    support@bin-gerne-hier.de
                  </a>{" "}
                  zugestellt werden.
                  <br />
                  <br />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </Container>
    </>
  );
};

export default HelpPage;
