import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { firebase } from "../services/config";
import {
  AccountCircle,
  MeetingRoom,
} from "@material-ui/icons";

// const useStyles = makeStyles({});

const HeaderBar = (props) => {
  // const classes = useStyles();
  const history = useHistory();
  const onLogout = () => {
    firebase
      .auth()
      .signOut()
      .then((response) => {
        // console.log("logged out");
        // props.navigation.navigate('Login');
        history.push("/login");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
        <Typography variant="h6" style={{ flex: 1 }}>
          {props.title}
        </Typography>
        {(!props.user) ? (
          <Button color="inherit" component={Link} to="/login">
            <AccountCircle /> Login
          </Button>
        ) : (
          <Button color="inherit" onClick={(event) => onLogout()}>
            <MeetingRoom /> Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
