import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { CropFree } from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { firebase } from "../services/config";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const EntriesPage = (props) => {
  const classes = useStyles();

  const [userId, setUserId] = useState("");
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  // if (props.user?.uid) {
  //   setUserId(props.user.uid);
  // }
  const abc = props.user?.uid;

  useEffect(() => {
    if (abc) {
      setLoading(true);
      firebase
        .firestore()
        .collection("Entry")
        .where("authorUid", "==", abc)
        // .orderBy("created", "desc")
        .onSnapshot(
          (querySnapshot) => {
            const newEntities = [];
            querySnapshot.forEach((doc) => {
              const entity = doc.data();
              entity.id = doc.id;
              newEntities.push(entity);
            });
            newEntities.sort((a, b) =>
              b.created - a.created
            );
            setEntries(newEntities);
            setLoading(false);
          },
          (error) => {
            console.log(error);
            setLoading(false);
          }
        );
    }
  }, []);

  const renderEntries = () => {
    return entries.map((el) => {
      return (
        <ListItem key={el.id}>
          <ListItemText primary={el.firstName}></ListItemText>
          <ListItemText primary={el.surname}></ListItemText>
          <ListItemText primary={el.city}></ListItemText>
          <ListItemText primary={(new Date(el.created.seconds * 1000)).toLocaleDateString() + " " + (new Date(el.created.seconds * 1000)).toLocaleTimeString()}></ListItemText>
        </ListItem>
      );
    });
  };

  return (
    <>
      <HeaderBar {...props} title="Einträge" user={props.user} />
      <br />
      <Container>
        <Grid container spacing={2}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography className={classes.pos} color="textSecondary">
                Eingetragene Besuche
              </Typography>
              <Typography variant="body2" component="p">
                {/* Um Ihren Besuchsverlauf / Einträge in Corona-Gästelisten müssen
                Sie sich kostenlos und einmalig registrieren.
                <br />
                Hierfür wird nur eine E-mail Adresse benötigt.
                <br />
                Nach der Registrierung können Sie sich mit Ihren Zugangsdaten
                anmelden und Ihre Eintäre im Blick behalten. */}
                {/* <br />
                <br /> */}
                Ihre Einträge werden 14 Tage gespeichert.
                <br />
                Nur der Gastgeber kann die Einträge sehen. Nach 14 Tagen werden
                die Einträge automatisch gelöscht.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item>
            <Button component={Link} to="/scan"><CropFree fontSize="large" /> QR-Code einscannen</Button>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                {!loading ? (
                  entries && entries.length ? (
                    <List>{renderEntries()}</List>
                  ) : (
                    <List>
                      <ListItem>Bisher sind keine Einträge vorhanden</ListItem>
                    </List>
                  )
                ) : (
                  <CircularProgress />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};

export default EntriesPage;
