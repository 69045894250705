import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import HeaderBar from "../components/HeaderBar";

function ImpressumPage(props) {
  return (
    <>
      <HeaderBar title="Impressum" />
      <br />
      <Container>
        <Grid container>
          <Grid item>
            <h2>Impressum</h2>

            <div className="row">
              <div className="col">
                RICH-Informatik Ridvan Chasan
                <br />
                <br />
                Allensteiner Str. 37
                <br />
                89584 Ehingen
                <br />
                <br />
                E-Mail: support@bin-gerne-hier.de
                <br />
                Internet: www.bin-gerne-hier.de
                <br />
                <br />
                Inhaber und Geschäftsführer:
                <br />
                Ridvan Chasan
                <br />
                <br />
                <br />
                <b>Haftungsausschluss:</b>
                <br />
                <br />
                Der Autor und die Informationsbereitsteller sind bemüht
                Informationen, die qualitativ hochwertig sind zur Verfügung zu
                stellen. Trotzdem übernimmt der Autor keinerlei Gewähr für die
                Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
                Informationen. Haftungsansprüche gegen den Autor, welche durch
                die Nutzung der dargebotenen Informationen bzw. durch die
                Nutzung fehlerhafter und unvollständiger Informationen
                verursacht wurden, sind grundsätzlich ausgeschlossen.
                <br />
                <br />
                <b>Haftungshinweis für Links:</b>
                <br />
                <br />
                Mit Urteil vom 12. Mai 1998 (Az 312 O 85/98) hat das Landgericht
                Hamburg entschieden, dass man durch die Ausbringung eines
                ‚Links‘ die Inhalte der gelinkten Seite ggf. mit zu verantworten
                hat. Dies kann, so das Landgericht in seiner Urteilsbegründung,
                nur dadurch verhindert werden, dass man sich ausdrücklich von
                diesen Inhalten distanziert. Wir haben von unseren Seiten
                ‚Links‘ zu anderen Seiten im Internet gelegt. Für all diese
                ‚Links‘ gilt: Wir weisen darauf hin, dass wir keinerlei Einfluss
                auf die Gestaltung und die Inhalte der verbundenen Seiten haben.
                Deshalb distanzieren wir uns ausdrücklich von allen Inhalten
                aller verbundenen Seiten. Diese Erklärung gilt für alle auf
                unserer Webseiten ausgebrachten ‚Links‘ und für alle Inhalte der
                Seiten, zu denen die ‚Links‘ führen.
                <br />
                <br />
                <b>Urheberrecht:</b>
                <br />
                <br />
                Die Betreiber der Seiten sind bemüht, stets die Urheberrechte
                anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie
                Werke zurückzugreifen. Die durch die Seitenbetreiber erstellten
                Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet.
                Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet.
                <br />
                <br />
                <b>Datenschutz:</b>
                <br />
                <br />
                Die <Link to="/datenschutz">Datenschutzerklärung</Link> finden
                Sie <Link to="/datenschutz">hier</Link>.
                <br />
                <br />
                <b>Rechtswirksamkeit dieses Haftungsausschlusses:</b>
                <br />
                <br />
                Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
                betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern
                Teile oder einzelne Formulierungen dieses Textes der geltenden
                Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
                sollten, bleiben die übrigen Teile des Dokumentes in ihrem
                Inhalt und ihrer Gültigkeit davon unberührt.
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
    </>
  );
}

export default ImpressumPage;
