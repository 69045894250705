function compare(a, b) {
  if (a.opened < b.opened) {
    return -1;
  }
  if (a.opened > b.opened) {
    return 1;
  }
  return 0;
}

export const updateRecentlyOpened = (checklist, type) => {
//   console.log('ssssssssssssss');
// //   await AsyncStorage.removeItem('@recentlyOpened');
//   checklist.opened = new Date();
//   checklist.type = type;

//   let recentlyOpened = await getRecentlyOpened();
//   console.log("recentlyOpened");
//   console.log(recentlyOpened);
//   if (recentlyOpened) {
//     recentlyOpened = recentlyOpened.sort(compare).slice(0, 2);

//     let index = recentlyOpened.findIndex((item) => item.id === checklist.id && 
//         item.categoryId === checklist.categoryId && item.type === checklist.type);
//         console.log("____");
//         console.log(index);
//       if (index !== -1) {
//         //console.log("found delete entry " + meinKapitalObject.id + " - " + meinKapitalObject.bank + " - " + meinKapitalObject.value);
//         recentlyOpened.splice(index, 1);
//       }

//   } else {

//     recentlyOpened = [];
//   }
//   recentlyOpened = recentlyOpened.slice(0, 2);
//   recentlyOpened.unshift(checklist);

//   try {
//     const jsonValue = JSON.stringify(recentlyOpened);
//     await AsyncStorage.setItem('@recentlyOpened', jsonValue);
//   } catch (e) {
//     // saving error
//     console.log('Error saving to AsyncStorage');
//   }

  // try {
  //   const jsonValue = JSON.stringify(value)
  //   await AsyncStorage.setItem('@storage_Key', jsonValue)
  // } catch (e) {
  //   // saving error
  // }
};

export const getMyDataGuest = () => {
  try {
    const jsonValue = localStorage.getItem("com.mowetec.coronaguestlist.myData");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
    return null;
  }
};

export const getMyLocalId = () => {
  try {
    const val = localStorage.getItem("com.mowetec.coronaguestlist.myLocalId");
    return (val != null && val != "") ? val : "";
  } catch (e) {
    // error reading value
    return "";
  }
};

export const setMyLocalId = (localId) => {
  try {
    const jsonValue = localStorage.setItem("com.mowetec.coronaguestlist.myLocalId", localId);
    return true;
  } catch (e) {
    // error reading value
    return false;
  }
};

export const setMyDataGuest = (data) => {
  try {
    localStorage.setItem(
        "com.mowetec.coronaguestlist.myData",
        JSON.stringify(data)
      );
    return true;
  } catch (e) {
    // error reading value
    return false;
  }
};

// }
