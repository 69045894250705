import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  NativeSelect,
  InputLabel,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Person, CheckCircleOutline } from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { Link } from "react-router-dom";
import { grey, blue } from "@material-ui/core/colors";
import { isValidEmail } from "../services/utils";
import { uuidv4 } from "../services/uuid";
import { firebase } from "../services/config";
import { createUserData } from "../services/firebaseService";
import { getMyLocalId, setMyLocalId } from "../services/storageService";
import logo from "../assets/bin-gerne-hier_Logo_2020.png";

const useStyles = makeStyles({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  loginContainer: {
    minWidth: 320,
    maxWidth: 400,
    height: "auto",
    position: "absolute",
    top: "10%",
    left: 0,
    right: 0,
    margin: "auto",
  },
  paper: {
    padding: 20,
    overflow: "auto",
  },
  buttonsDiv: {
    textAlign: "center",
    padding: 10,
  },
  flatButton: {
    color: grey,
  },
  checkRemember: {
    style: {
      float: "left",
      maxWidth: 180,
      paddingTop: 5,
    },
    labelStyle: {
      color: grey,
    },
    iconStyle: {
      color: grey,
      borderColor: grey,
      fill: { grey },
    },
  },
  loginBtn: {
    float: "right",
  },
  btn: {
    background: "#4f81e9",
    color: blue,
    padding: 7,
    borderRadius: 2,
    margin: 2,
    fontSize: 13,
  },
  btnFacebook: {
    background: "#4f81e9",
  },
  btnGoogle: {
    background: "#e14441",
  },
  btnSpan: {
    marginLeft: 5,
  },
});

const RegisterPage = () => {
  const classes = useStyles();
  const history = useHistory();
  // const bull = <span className={classes.bullet}>•</span>;
  const [role, setRole] = useState("guest");
  const [betriebName, setBetriebName] = useState("");
  const [plz, setPlz] = useState("");
  const [ort, setOrt] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registeredUser, setRegisteredUser] = useState(false);

  const onSavePress = () => {
    if (!email || !isValidEmail(email)) {
      // Todo Snackbar Alert
      alert("Bitte eine gültige E-Mail-Adresse eingeben.");
      return;
    }
    if (!password || password.length < 5) {
      // Todo Snackbar Alert
      alert("Das Passwort muss mindestens 5 Zeichen haben.");
      return;
    }
    if (!betriebName || betriebName.length < 2) {
      // Todo Snackbar Alert
      alert("Name des Betriebs darf nicht leer sein.");
      return;
    }
    if (!plz || plz.length != 5) {
      // Todo Snackbar Alert
      alert("Postleitzahl darf nicht leer sein.");
      return;
    }
    if (!ort || ort.length < 2) {
      // Todo Snackbar Alert
      alert("Ort darf nicht leer sein.");
      return;
    }
    // if (!privacyCheck) {
    //   // Todo Snackbar Alert
    //   alert("Bitte die Datenschutzerklärung bestätigen");
    //   return;
    // }
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        const uid = response.user.uid;
        const user = response.user;

        if (user) {
          user.sendEmailVerification();
        }

        var localId = getMyLocalId();

        var newId = localId ? localId : uuidv4();
        setMyLocalId(newId);

        setRegisteredUser(true);
        createUserData(uid, newId, role, betriebName, plz, ort);
        setLoading(false);
        // navigation.navigate("Home", { user: data });
        history.push("/");
        // const usersRef = firebase.firestore().collection('users');
        // usersRef
        //   .doc(uid)
        //   .set(data)
        //   .then(() => {
        //     navigation.navigate('Home', {user: data});
        //   })
        //   .catch((error) => {
        //     alert(error);
        //   });
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <>
      <HeaderBar title="Registrieren" />
      <br />
      <Container className={classes.loginContainer}>
        <Grid container justify="center">
          <Grid item>
            <img src={logo} alt="Logo" />
          </Grid>
        </Grid>
        <Paper spacing={2} className={classes.paper}>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  <b>Gastgeber/Gastwirt</b> kostenlos registrieren
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-native-helper">Rolle</InputLabel>
                  <NativeSelect
                    value={role}
                    onChange={handleRoleChange}
                    inputProps={{
                      name: "role",
                      id: "role-native-helper",
                    }}
                  >
                    <option value="gastro">Geastgeber/Gastwirt</option>
                    {/* <option value="guest">Gast/Besucher/Kunde</option> */}
                  </NativeSelect>
                  <FormHelperText>Kunde oder Gastgeber?</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="betriebName"
                  type="text"
                  label="Name des Betriebs"
                  fullWidth
                  value={betriebName}
                  onChange={(event) => setBetriebName(event.target.value)}
                  error={betriebName.length < 3}
                  helperText={betriebName.length < 3 ? "Bitte ausfüllen!" : " "}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="plz"
                  type="text"
                  label="Postleitzahl"
                  fullWidth
                  value={plz}
                  onChange={(event) => setPlz(event.target.value)}
                  error={plz.length != 5}
                  helperText={plz.length != 5 ? "Bitte ausfüllen!" : " "}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ort"
                  type="text"
                  label="Ort"
                  fullWidth
                  value={ort}
                  onChange={(event) => setOrt(event.target.value)}
                  error={ort.length < 2}
                  helperText={ort.length < 2 ? "Bitte ausfüllen!" : " "}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  type="email"
                  label="E-Mail"
                  fullWidth
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={email.length < 3}
                  helperText={email.length < 3 ? "Bitte ausfüllen!" : " "}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  type="password"
                  label="Passwort"
                  fullWidth
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  error={password.length < 5}
                  helperText={password.length < 5 ? "Bitte ausfüllen!" : " "}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="privacyCheck"
                      value={privacyCheck}
                      // checked={privacyCheck}
                      onChange={(event) => setPrivacyCheck(event.target.value)}
                      name="privacyCheck"
                      color="primary"
                    />
                  }
                  // label={"Ja, ich habe die " + (<Link to="/datenschutz">Datenschutzerklärung</Link>) + " gelesen und bin damit einverstanden."}
                label={ <div> <span>Ja, ich habe die </span> <Link to={'/datenschutz'} target="_blank">Datenschutzerklärung</Link>  gelesen und bin damit einverstanden.</div> }
                />
              </Grid>
              <Grid item xs={12}>
                {!loading && !registeredUser ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => onSavePress()}
                    >
                      Benutzerkonto erstellen
                    </Button>
                    {registeredUser ? <CheckCircleOutline /> : ""}
                  </>
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
        <br />
        <Grid container spacing={3} alignItems="center" alignContent="center">
          <Grid item xs={12}>
            Sie haben bereits ein Konto?
            <br />
            <Button color="default" component={Link} to="/login">
              <Person /> Jetzt anmelden
            </Button>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default RegisterPage;
