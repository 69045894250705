import React, { useState } from "react";
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { CropFree } from "@material-ui/icons";
import QrReader from "react-qr-reader";
import HeaderBar from "../components/HeaderBar";
import { createEntry } from "../services/firebaseService";
import { getMyLocalId } from "../services/storageService";
import { uuidv4 } from "../services/uuid";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

// TODO: Offline-Modus: Scans in localStroage speichern, falls kein Internet.
// Falls Internet wieder da, LocalStorage abschicken und leeren.

const ScanPage = (props) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [streetAndNumber, setStreetAndNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [scanResult, setScanResult] = useState("No result.");
  const [showReader, setShowReader] = useState(true);
  const [showScanResult, setShowScanResult] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  function postScanResult(data) {
    if (data) {
      var dataObject = JSON.parse(data);

      if (
        dataObject?.firstName &&
        dataObject.surname &&
        dataObject.streetAndNumber &&
        dataObject.city
      ) {
        setFirstName(dataObject.firstName);
        setSurname(dataObject.surname);
        setCity(dataObject.city);

        var myLocalId = getMyLocalId();
        var newId = myLocalId ? myLocalId : uuidv4();
        createEntry(props.user.uid, newId, dataObject);
        setOpenSuccess(true);
      } else {
        setFirstName("");
        setSurname("");
        setCity("");
        setOpenError(true);
        window.navigator.vibrate(300);
      }
    }
  }

  function handleScan(data) {
    if (data) {
      try {
        // TODO: differenzieren: Im Erfolg kurz und falls falscher Code länger vibrieren
        window.navigator.vibrate(300);
      } catch (err) {
        console.log(err);
      }
      setScanResult(data);
      if (
        data.indexOf("id") < 0 ||
        data.indexOf("firstName") < 0 ||
        data.indexOf("surname") < 0 ||
        data.indexOf("city") < 0
      ) {
        setFirstName("");
        setSurname("");
        setCity("");
        setOpenError(true);
        window.navigator.vibrate(300);
      } else {
        // showScanResult(data);
        postScanResult(data);
        // hideReader();
        // showReader();
      }
    }
  }

  //   handleScan = (data) => {
  //     if (data) {
  //       setScanResult(data);
  //     }
  //   };

  //   handleError = (err) => {
  //     console.error(err);
  //   };

  function handleError(err) {
    console.log(err);
  }

  return (
    <>
      <HeaderBar {...props} title="Code scannen" user={props.user} />
      <br />
      <Container>
        <Grid>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              {showReader ? (
                <QrReader
                  delay={2000}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "90%" }}
                />
              ) : (
                ""
              )}
              {firstName && surname && city ? (
                <Typography className={classes.pos} variant="h4" gutterBottom>
                  Gast: {firstName} {surname} aus {city}
                </Typography>
              ) : (
                ""
              )}
              {showScanResult ? <p>{scanResult}</p> : ""}
            </CardContent>
          </Card>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSuccess}
          autoHideDuration={2000}
          onClose={handleCloseSuccess}
        >
          <Alert onClose={handleCloseSuccess} severity="success">
            Daten erfolgreich gespeichert
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openError}
          autoHideDuration={4000}
          onClose={handleCloseError}
        >
          <Alert onClose={handleCloseError} severity="error">
            Daten im Barcode nicht gültig. Bitte lassen Sie die Profildaten
            prüfen und scannen dann den QR-Code erneut ein.
          </Alert>
        </Snackbar>
      </Container>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default ScanPage;
