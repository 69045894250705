import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { firebase } from "../services/config";
import {
  AccountCircle,
  MeetingRoom,
} from "@material-ui/icons";

// const useStyles = makeStyles({});
const useStyles = makeStyles({
    noDecoratedLink: {
        textDecoration: 'none',
        color: 'black'
      }
  });

const HeaderBar = (props) => {
  const classes = useStyles();


  return (
    <Grid container>
        <Grid item xs={6} md={6} lg={6} align="center">
            <Typography paragraph>              
              <Link className={classes.noDecoratedLink} to="/datenschutz">Datenschutz</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6} align="center">
            <Typography paragraph>              
              <Link className={classes.noDecoratedLink} to="/impressum">Impressum</Link>
            </Typography>
          </Grid>
    </Grid>
  );
};

export default HeaderBar;
