import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import HeaderBar from "../components/HeaderBar";
import { Person, Close, Check, AccountCircle } from "@material-ui/icons";
import { firebase } from "../services/config";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const HerePage = (props) => {
  const classes = useStyles();

  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [streetAndNumber, setStreetAndNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [localeId, setLocaleId] = useState("");
  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    
    setLocaleId(props.match.params.id);

    // if (localeId) {
      setLoading(true);
      firebase
        .firestore()
        .collection("UserData")
        .doc(props.match.params.id)
        // .where("localId", "==", localeId)
        // // .orderBy("created", "desc")
        .get()
        .then(function (doc) {
          if (doc.exists) {
            const entity = doc.data();
            entity.id = doc.id;
            setEntry(entity);
            setLoading(false);
          } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
            setLoading(false);
          }
        })
        .catch(function (error) {
          // console.log(error);
          setLoading(false);
        });
    // }
  }, [props.match.params.id]);


  const onSavePress = () => {

  }

  return (
    <>
      <HeaderBar title="Lokal" />
      <br />
      <Container>
        <Grid>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              {!loading ? (
                entry ? (
                  <>
                    <Typography className={classes.pos} color="textSecondary">
                      Eintrag in die Gästeliste von <b>{entry.name}</b>
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2" component="p">
                    Gästeregistrierung für diesen Betrieb noch nicht möglich.<br />
                    Haben Sie einen ungültigen QR-Code eingescannt? Probieren Sie es bitte erneut.
                  </Typography>
                )
              ) : (
                <CircularProgress />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={2}>
                  <Person />
                </Grid>
                <Grid item xs={10}>
                  <Typography component="p">Meine Profildaten</Typography>
                </Grid>
              </Grid>
              {/* <Typography className={classes.title} gutterBottom>
                <Person /> Meine Profildaten
              </Typography> */}
              <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="firstName"
                      label="Vorname*"
                      fullWidth
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      error={firstName === ""}
                      helperText={firstName === "" ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="surname"
                      label="Name*"
                      fullWidth
                      value={surname}
                      onChange={(event) => setSurname(event.target.value)}
                      error={surname === ""}
                      helperText={surname === "" ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="streetAndNumber"
                      label="Straße und Hausnummer*"
                      fullWidth
                      value={streetAndNumber}
                      onChange={(event) =>
                        setStreetAndNumber(event.target.value)
                      }
                      error={streetAndNumber.length < 3}
                      helperText={
                        streetAndNumber.length < 3 ? "Bitte ausfüllen!" : " "
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="postalCode"
                      label="Postleitzahl*"
                      fullWidth
                      value={postalCode}
                      onChange={(event) => setPostalCode(event.target.value)}
                      error={postalCode.length != 5}
                      helperText={
                        (postalCode.length != 5) === ""
                          ? "Bitte eien gültige Postleitzahl eintragen!"
                          : " "
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="city"
                      label="Ort*"
                      fullWidth
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                      error={city.length < 2}
                      helperText={city.length < 2 ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="phone"
                      label="Telefon*"
                      fullWidth
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      error={phone.length < 7}
                      helperText={phone.length < 7 ? "Bitte ausfüllen!" : " "}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      type="email"
                      label="E-Mail"
                      fullWidth
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => onSavePress()}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </>
  );
};

export default HerePage;
