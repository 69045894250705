import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { firebase } from "./services/config";
// import logo from "./assets/logo.svg";
import "./App.css";
import StartPage from "./pages/StartPage";
import HelpPage from "./pages/HelpPage";
import TabNavbar from "./components/TabNavbar";
import MyDataPage from "./pages/MyDataPage";
import EntriesPage from "./pages/EntriesPage";
import MyCodePage from "./pages/MyCodePage";
import ScanPage from "./pages/ScanPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import HerePage from "./pages/HerePage";
import PrintPage from "./pages/PrintPage";
import MenuCardEditPage from "./pages/MenuCardEditPage";
import Footer from "./components/Footer";
import ImpressumPage from "./pages/ImpressumPage";
import DatenschutzPage from "./pages/DatenschutzPage";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        // User is signed in.
        setUser(currentUser);
      } else {
        // No user is signed in.
      }
    });
  }, []);


  return (
    <BrowserRouter>
      <CssBaseline />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <StartPage {...props} user={user} />}
        />
        <Route
          exact
          path="/my-data"
          render={(props) => <MyDataPage {...props} user={user} />}
        />
        <Route
          exact
          path="/entries"
          render={(props) => <EntriesPage {...props} user={user} />}
        />
        <Route exact path="/help" component={HelpPage}></Route>
        <Route
          exact
          path="/scan"
          render={(props) => <ScanPage {...props} user={user} />}
        />
        <Route
          exact
          path="/here/:id?"
          render={(props) => <HerePage {...props} user={user} />}
        />
        <Route
          exact
          path="/print"
          render={(props) => <PrintPage {...props} user={user} />}
        />
        <Route
          exact
          path="/editmenu"
          render={(props) => <MenuCardEditPage {...props} user={user} />}
        />
        {/* <Route exact path="/scan" component={ScanPage}></Route> */}
        <Route exact path="/my-code" component={MyCodePage}></Route>
        <Route exact path="/login" component={LoginPage}></Route>
        <Route exact path="/register" component={RegisterPage}></Route>
        <Route
          exact
          path="/passwordrecovery"
          component={PasswordRecoveryPage}
        ></Route>
        <Route exact path="/impressum" component={ImpressumPage}></Route>
        <Route exact path="/datenschutz" component={DatenschutzPage}></Route>
      </Switch>
      <Footer />
      <br />
      <br />
      <br />
      <br />
      <TabNavbar user={user} />
    </BrowserRouter>
  );
}

export default App;
