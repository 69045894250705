// import firebase from "@react-native-firebase/app";
// import Auth from "@react-native-firebase/auth";
// import Firestore from "@react-native-firebase/firestore";
import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// export { firebase, Auth, Firestore };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// export { firebase };

export { firebase };
