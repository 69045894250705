import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Help, PersonAdd } from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { Link } from "react-router-dom";
import { grey, blue } from "@material-ui/core/colors";
import { firebase } from "../services/config";
import { isValidEmail } from "../services/utils";
import logo from "../assets/bin-gerne-hier_Logo_2020.png";

const useStyles = makeStyles({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  loginContainer: {
    minWidth: 320,
    maxWidth: 400,
    height: "auto",
    position: "absolute",
    top: "10%",
    left: 0,
    right: 0,
    margin: "auto",
  },
  paper: {
    padding: 20,
    overflow: "auto",
  },
  buttonsDiv: {
    textAlign: "center",
    padding: 10,
  },
  flatButton: {
    color: grey,
  },
  checkRemember: {
    style: {
      float: "left",
      maxWidth: 180,
      paddingTop: 5,
    },
    labelStyle: {
      color: grey,
    },
    iconStyle: {
      color: grey,
      borderColor: grey,
      fill: { grey },
    },
  },
  loginBtn: {
    float: "right",
  },
  btn: {
    background: "#4f81e9",
    color: blue,
    padding: 7,
    borderRadius: 2,
    margin: 2,
    fontSize: 13,
  },
  btnFacebook: {
    background: "#4f81e9",
  },
  btnGoogle: {
    background: "#e14441",
  },
  btnSpan: {
    marginLeft: 5,
  },
});

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  // const bull = <span className={classes.bullet}>•</span>;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onLoginClick = () => {
    if (!email || !isValidEmail(email)) {
      // Todo Snackbar Alert
      alert("Bitte eine gültige E-Mail-Adresse eingeben.");
      return;
    }
    if (!password || password.length < 5) {
      // Todo Snackbar Alert
      alert("Das Passwort muss mindestens 5 Zeichen haben.");
      return;
    }
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        const uid = response.user.uid;

        const data = response.user;
        setLoading(false);
        // navigation.navigate("Home", { user: data });
        history.push("/");
        // const usersRef = firebase.firestore().collection('users');
        // usersRef
        //   .doc(uid)
        //   .set(data)
        //   .then(() => {
        //     navigation.navigate('Home', {user: data});
        //   })
        //   .catch((error) => {
        //     alert(error);
        //   });
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  return (
    <>
      <HeaderBar title="Login" />
      <br />
      <Container className={classes.loginContainer}>
        <Grid container justify="center">
          <Grid item>
            <img src={logo} alt="Logo" />
          </Grid>
        </Grid>
        <Paper spacing={2} className={classes.paper}>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  Als <b>Gastgeber/Gastwirt</b> anmelden
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  type="email"
                  label="E-Mail"
                  fullWidth
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={email.length < 3}
                  helperText={email.length < 3 ? "Bitte ausfüllen!" : " "}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  type="password"
                  label="Passwort"
                  fullWidth
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  error={password.length < 5}
                  helperText={password.length < 5 ? "Bitte ausfüllen!" : " "}
                />
              </Grid>
              <Grid item xs={12}>
                {!loading ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => onLoginClick()}
                    >
                      Login
                    </Button>
                  </>
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
        <br />
        <Grid container spacing={3} alignItems="center" alignContent="center">
          <Grid item xs={6}>
            <Button color="default" component={Link} to="/register">
              <PersonAdd /> Registrieren
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="default" component={Link} to="/passwordrecovery">
              <Help /> Passwort vergessen
            </Button>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default LoginPage;
