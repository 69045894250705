import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Edit, NavigateNext } from "@material-ui/icons";
import HeaderBar from "../components/HeaderBar";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  qrCodeDiv: {
    width: 512,
    height: 512,
    margin: 10,
    padding: 10,
  },
});

const MyCodePage = (props) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [streetAndNumber, setStreetAndNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [myCodeData, setMyCodeData] = useState("");

  useEffect(() => {
    if (localStorage.getItem("com.mowetec.coronaguestlist.myData")) {
      // Retrieve the object from storage
      var retrievedMyDataString = localStorage.getItem(
        "com.mowetec.coronaguestlist.myData"
      );

      var retrievedMyDataObject = JSON.parse(retrievedMyDataString);
      setMyCodeData(retrievedMyDataString);
      setFirstName(retrievedMyDataObject.firstName);
      setSurname(retrievedMyDataObject.surname);
      // setStreetAndNumber(retrievedMyDataObject.streetAndNumber);
      // setPostalCode(retrievedMyDataObject.postalCode);
      setCity(retrievedMyDataObject.city);
      // setPhone(retrievedMyDataObject.phone);
      // setEmail(retrievedMyDataObject.email);

      // var myCode =
    }
  }, []);

  return (
    <>
      <HeaderBar {...props} title="Mein Code" user={props.user} />
      <br />
      <Container>
        {firstName && surname && myCodeData ? (
          <>
            <Grid>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography className={classes.pos} variant="h5" gutterBottom>
                    Gast: {firstName} {surname} aus {city}
                  </Typography>
                  <hr />
                  <div className={classes.qrCodeDiv}>
                    <QRCode value={myCodeData} size={256} renderAs="svg" />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : (
          <>
            <br />
            <Grid>
              <CardActionArea component={RouterLink} to="/my-data">
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <Edit fontSize="large" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="h5" className={classes.pos}>
                          Kontaktdaten hinterlegen
                        </Typography>
                        <Typography variant="body2" component="p">
                          Bevor Sie sich in eine Gästeliste eintragen können,
                          müssen Sie Ihre Kontaktdaten hinterlegen.
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <NavigateNext fontSize="large" color="disabled" />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </>
        )}
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};

export default MyCodePage;
